import Image from "next/legacy/image";
import { FC, PropsWithChildren } from "react";
import { Headline, Paragraph, Section } from "../../components";
import styles from "./BlogLayout.module.scss";
interface BlogLayoutProps {
	title: string;
	headerTitle?: string;
	dateTimeStamp?: string;
	date?: string;
	teaser?: string;
	imageSrc?: string;
	imageAlt?: string;
}

export const BlogLayout: FC<PropsWithChildren<BlogLayoutProps>> = ({
	teaser,
	dateTimeStamp,
	headerTitle,
	children,
	imageSrc,
	imageAlt,
	date,
	title,
	...rest
}) => {
	return (
		<article className={styles.blogLayout} {...rest}>
			<Section maxWidth={"sm"}>
				{headerTitle && <Headline level={3}>{headerTitle}</Headline>}
				{title && <Headline level={1}>{title}</Headline>}
				{date && <time dateTime={dateTimeStamp}>{date}</time>}
				{teaser && <Paragraph size={"lg"}>{teaser}</Paragraph>}
			</Section>
			{imageSrc && (
				<div className={styles.imageContainer}>
					<Image
						src={imageSrc}
						className={styles.stageImage}
						alt={imageAlt}
						width={1600}
						height={800}
						objectFit={"cover"}
						layout={"responsive"}
					/>
				</div>
			)}
			{children}
		</article>
	);
};
