import {NextPage} from "next";
import Link from "next/link";
import {useEffect, useState} from "react";
import {DynamicSeo} from "seo";
import {
    Button,
    Card,
    CardGrid,
    Grid,
    GridColumn,
    GridRow,
    Headline,
    Hotspot,
    HotspotDot,
    HotspotDots,
    HotspotImage,
    Icon,
    icons,
    Input,
    Paragraph,
    Section,
    Snippet,
    Window,
} from "../../components";
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel} from "../../components/Accordion";
import {Code} from "../../components/Code";
import {HotspotItem} from "../../components/Hotspot/HotspotItem";
import {Media} from "../../components/Media";
import {Slider} from "../../components/Slider/Slider";
import styles from "./StyleguideModule.module.scss";

const HTML_INPUT_TYPES = [
    "text",
    "password",
    "submit",
    "reset",
    "radio",
    "checkbox",
    "button",
    "file",
    "image",
    "color",
    "date",
    "datetime-local",
    "email",
    "month",
    "number",
    "url",
    "week",
    "search",
    "tel",
];
export const StyleguideModule: NextPage = () => {
    const [scrollSpyList, setScrollSpyList] = useState<string[]>([]);
    useEffect(() => {
        let scrollSpyItems = Array.from(document.querySelectorAll("[data-scroll-spy]")).map((value) => {
            return value.id;
        });
        console.log(scrollSpyItems);
        setScrollSpyList(scrollSpyItems);
        return () => {
        };
    }, []);
    return (
        <div>
            <DynamicSeo title={"Styleguide"}/>
            <Section fullWidth={true} horizontalAlignment={"center"} verticalAlignment={"center"} fullHeight={true}>
                <Headline level={1}>Styleguide</Headline>
            </Section>

            <nav className={styles.scrollSpyNav}>
                <ul className={styles.scrollSpyList}>
                    {scrollSpyList.map((item, index) => {
                        return (
                            <li>
                                <Link href={"#" + item} className={styles.scrollSpyLink}>
                                    {item}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </nav>
            <Section contentFullWidth={true} data-scroll-spy id={"Typography"}>
                <Headline level={2}>Typography</Headline>
                <Headline level={1}>Heading h1</Headline>
                <Headline level={2}>Heading h2</Headline>
                <Headline level={3}>Heading h3</Headline>
                <Headline level={4}>Heading h4</Headline>
                <Headline level={5}>Heading h5</Headline>
                <Headline level={6}>Heading h6</Headline>
                <Paragraph>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                    et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                    et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                </Paragraph>
                <ul>
                    {[...Array(5)].map((e, i) => {
                        return <li key={i}>Unordered list Item {i}</li>;
                    })}
                </ul>
                <ol>
                    {[...Array(5)].map((e, i) => {
                        return <li key={i}>Ordered list Item {i}</li>;
                    })}
                </ol>
            </Section>
            <Section contentFullWidth={true} data-scroll-spy id={"Inputs"}>
                <Headline level={2}>Inputs</Headline>
                <Headline level={3}>Inputs (default)</Headline>

                {HTML_INPUT_TYPES.map((type, index) => {
                    return (
                        <>
                            <Input type={type} placeholder={type}/><br/>
                        </>
                    );
                })}
                <br/>
                <Headline level={3}>Inputs (with label)</Headline>

                {HTML_INPUT_TYPES.map((type, index) => {
                    return (
                        <>
                            <Input type={type} placeholder={type} label={type}/><br/>
                        </>
                    );
                })}

            </Section>
            <Section contentFullWidth={true} data-scroll-spy id={"Icons"}>
                <Headline level={2}>Icons</Headline>
                <Grid>
                    <GridRow>
                        {icons.map((icon, index) => {
                            return (
                                <GridColumn key={index}>
                                    <Icon icon={icon}/>
                                </GridColumn>
                            );
                        })}
                    </GridRow>
                </Grid>
            </Section>
            <Section contentFullWidth={true} data-scroll-spy id={"Buttons"}>
                <Headline level={2}>Buttons</Headline>
                <Button>Button</Button>
                <Button variant={"link"}>Link button</Button>
                <Button variant={"link"}>Link button</Button>
            </Section>
            <Section contentFullWidth={true} data-scroll-spy id={"Accordion"}>
                <Headline level={2}>Accordion</Headline>
                <Headline level={3}>Accordion (default)</Headline>
                <Accordion>
                    <AccordionItem>
                        <AccordionItemHeading>Accordion Title 1</AccordionItemHeading>
                        <AccordionItemPanel>Content of Item 1</AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>Accordion Title 2</AccordionItemHeading>
                        <AccordionItemPanel>Content of Item 2</AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>Accordion Title 3</AccordionItemHeading>
                        <AccordionItemPanel>Content of Item 3</AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>Accordion Title 4</AccordionItemHeading>
                        <AccordionItemPanel>Content of Item 4</AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
                <Headline level={3}>Accordion (multiple)</Headline>

                <Accordion multiple={true}>
                    <AccordionItem>
                        <AccordionItemHeading>Accordion Title 1</AccordionItemHeading>
                        <AccordionItemPanel>Content of Item 1</AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>Accordion Title 2</AccordionItemHeading>
                        <AccordionItemPanel>Content of Item 2</AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>Accordion Title 3</AccordionItemHeading>
                        <AccordionItemPanel>Content of Item 3</AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>Accordion Title 4</AccordionItemHeading>
                        <AccordionItemPanel>Content of Item 4</AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </Section>
            <Section contentFullWidth={true} data-scroll-spy id={"Code"}>
                <Headline level={2}>Code</Headline>
                <Code
                    language={"ts"}
                    fileName={"Demo.ts"}
                    value={
                        "interface User {\n" +
                        "  name: string;\n" +
                        "  id: number;\n" +
                        "}\n" +
                        " \n" +
                        "class UserAccount {\n" +
                        "  name: string;\n" +
                        "  id: number;\n" +
                        " \n" +
                        "  constructor(name: string, id: number) {\n" +
                        "    this.name = name;\n" +
                        "    this.id = id;\n" +
                        "  }\n" +
                        "}\n" +
                        " \n" +
                        'const user: User = new UserAccount("Murphy", 1);'
                    }
                />
                <Code
                    language={"scss"}
                    value={
                        "$font-stack: Helvetica, sans-serif;\n" +
                        "$primary-color: #333;\n" +
                        "\n" +
                        "body {\n" +
                        "  font: 100% $font-stack;\n" +
                        "  color: $primary-color;\n" +
                        "}"
                    }
                />
                <Code
                    language={"html"}
                    value={
                        "<!DOCTYPE html>\n" +
                        '<html lang="en">\n' +
                        "  <head>\n" +
                        '    <meta charset="UTF-8">\n' +
                        "    <title>Title</title>\n" +
                        "  </head>\n" +
                        "  <body>\n" +
                        "\n" +
                        "  </body>\n" +
                        "</html>"
                    }
                />
            </Section>
            <Section contentFullWidth={true} data-scroll-spy id={"Window"}>
                <Headline level={2}>Window</Headline>
                <Window></Window>
            </Section>
            <Section contentFullWidth={true} data-scroll-spy id={"Snippet"}>
                <Headline level={2}>Snippet</Headline>
                <Snippet text={"npm install"}/>
            </Section>
            <Section contentFullWidth={true} data-scroll-spy id={"Card"}>
                <Headline level={2}>Cards</Headline>
                <CardGrid>
                    {[...Array(3)].map((e, i) => {
                        return (
                            <Card
                                href={"#"}
                                headerLabel={"Header label"}
                                title={"Card title"}
                                subTitle={"Card subtitle"}
                                imageAlt={"Card image"}
                                imageSrc={"/assets/card-example.jpeg"}></Card>
                        );
                    })}
                </CardGrid>
                <CardGrid>
                    {[...Array(3)].map((e, i) => {
                        return (
                            <Card
                                href={"#"}
                                headerLabel={"Header label"}
                                contentAboveImage={true}
                                title={"Card title"}
                                subTitle={"Card subtitle"}
                                imageAlt={"Card image"}
                                imageSrc={"/assets/card-example.jpeg"}></Card>
                        );
                    })}
                </CardGrid>
                <CardGrid>
                    {[...Array(3)].map((e, i) => {
                        return (
                            <Card
                                href={"#"}
                                headerLabel={"Header label"}
                                title={"Card title"}
                                subTitle={"Card subtitle"}
                                imageAlt={"Card image"}></Card>
                        );
                    })}
                </CardGrid>
                <Slider>
                    {[...Array(10)].map((e, i) => {
                        return (
                            <Card
                                imageSrc={"/assets/card-example.jpeg"}
                                href={"#"}
                                key={i}
                                headerLabel={"Header label"}
                                title={"Card title"}
                                subTitle={"Card subtitle"}
                                imageAlt={"Card image"}></Card>
                        );
                    })}
                </Slider>
                <Slider overflow={true}>
                    {[...Array(10)].map((e, i) => {
                        return (
                            <Card
                                imageSrc={"/assets/card-example.jpeg"}
                                href={"#"}
                                key={i}
                                headerLabel={"Header label"}
                                title={"Card title"}
                                subTitle={"Card subtitle"}
                                imageAlt={"Card image"}></Card>
                        );
                    })}
                </Slider>
            </Section>
            <Section contentFullWidth={true} data-scroll-spy id={"Media"}>
                <Headline level={2}>Media</Headline>
                <Media src={"/assets/card-example.jpeg"} width={5000} height={3000} lightBox={true}/>
            </Section>
            <Section contentFullWidth={true} data-scroll-spy id={"Hotspot Image"}>
                <Headline level={2}>Hotspot Image</Headline>
                <Hotspot>
                    <HotspotImage width={4096} height={2733} imageSrc={"/assets/hotspot-example.jpg"}/>
                    <HotspotDots>
                        <HotspotItem>
                            <HotspotDot left={10} top={55}/>
                        </HotspotItem>
                        <HotspotItem>
                            <HotspotDot left={50} top={45}/>
                        </HotspotItem>
                        <HotspotItem>
                            <HotspotDot left={75} top={45}/>
                        </HotspotItem>
                        <HotspotItem>
                            <HotspotDot left={80} top={80}/>
                        </HotspotItem>
                    </HotspotDots>
                </Hotspot>
            </Section>
        </div>
    );
}
    ;
