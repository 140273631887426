import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";
import { useAccordionItemContext } from "../../providers/AccordionItemProvider";
import styles from "./AccordionItemPanel.module.scss";
export const AccordionItemPanel: FC<PropsWithChildren> = ({ children }) => {
	const ref = useRef(null);
	const [height, setHeight] = useState<number>(null);
	const [cssHeight, setCssHeight] = useState<string>("auto");
	const { isActive } = useAccordionItemContext();
	useEffect(() => {
		setHeight(ref.current.clientHeight);
	}, []);
	useEffect(() => {
		setCssHeight(isActive && height ? height + "px" : "0px");
	}, [isActive]);
	return (
		<>
			<div className={styles.panel} ref={ref} style={{ height: cssHeight }}>
				<div className={styles.content}>{children}</div>
			</div>
		</>
	);
};
