import Image from "next/legacy/image";
import { FC, MutableRefObject } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import styles from "./Media.module.scss";
interface MediaProps {
	src: string;
	description?: string;
	lightBox?: boolean;
	width: number;
	height: number;
}

export const Media: FC<MediaProps> = ({ src, width = null, height = null, description, lightBox }) => {
	const ImageComponent = (
		<Image className={styles.image} src={src} width={width} height={height} objectFit={"contain"} />
	);
	if (lightBox) {
		return (
			<>
				<Gallery>
					<Item original={src} width={width} height={height}>
						{({ ref, open }) => (
							<figure
								className={styles.lightboxItem}
								ref={ref as MutableRefObject<HTMLImageElement>}
								onClick={open}>
								{ImageComponent}
								{description && <figcaption>{description}</figcaption>}
							</figure>
						)}
					</Item>
				</Gallery>
			</>
		);
	} else {
		return <>{ImageComponent}</>;
	}
};
