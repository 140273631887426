import { FC } from "react";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";

import html from "react-syntax-highlighter/dist/cjs/languages/prism/cshtml";
import js from "react-syntax-highlighter/dist/cjs/languages/prism/javascript";
import scss from "react-syntax-highlighter/dist/cjs/languages/prism/scss";
import tsx from "react-syntax-highlighter/dist/cjs/languages/prism/tsx";
import ts from "react-syntax-highlighter/dist/cjs/languages/prism/typescript";
import atomDark from "react-syntax-highlighter/dist/cjs/styles/prism/atom-dark";
import styles from "./Code.module.scss";
SyntaxHighlighter.registerLanguage("html", html);
SyntaxHighlighter.registerLanguage("js", js);
SyntaxHighlighter.registerLanguage("scss", scss);
SyntaxHighlighter.registerLanguage("ts", ts);
SyntaxHighlighter.registerLanguage("tsx", tsx);
interface CodeProps {
	language: "html" | "js" | "ts" | "scss" | "tsx";
	value: string;
	fileName?: string;
}

export const Code: FC<CodeProps> = ({ value, fileName, language, ...rest }) => {
	return (
		<div className={styles.code} {...rest}>
			{fileName && <div>{fileName}</div>}
			<SyntaxHighlighter
				customStyle={{ padding: "1rem" }}
				language={language}
				showLineNumbers={true}
				style={atomDark}>
				{value}
			</SyntaxHighlighter>
		</div>
	);
};
