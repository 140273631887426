import classNames from "classnames";
import { Children, FC, PropsWithChildren } from "react";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperProps } from "swiper/react/swiper-react";
import styles from "./Slider.module.scss";
interface SliderProps extends SwiperProps {
	overflow?: boolean;
}
export const Slider: FC<PropsWithChildren<SliderProps>> = ({ children, overflow }) => {
	const _classNames = classNames(styles.slider, {
		[styles.sliderWithOverflow]: overflow,
	});
	return (
		<Swiper
			modules={[Navigation, Pagination, Scrollbar, A11y]}
			spaceBetween={15}
			slidesPerView={3}
			navigation
			pagination={{ clickable: true }}
			scrollbar={{ draggable: true }}
			className={_classNames}
			onSwiper={(swiper) => console.log(swiper)}
			onSlideChange={() => console.log("slide change")}>
			{Children.map(children, (child) => (
				<SwiperSlide>{child}</SwiperSlide>
			))}
		</Swiper>
	);
};
